import React, { Component } from "react";
import "./main.css";

class Item extends Component {
  state = {
    itemClass:"",
    itemInfo:"",
    itemMedia:"",
  }
  componentDidMount = () => {
    if (this.props.num % 1 === 0) {
      this.setState({
        itemClass: "itemRight",
        itemInfo:"infoRight",
        itemMedia:"mediaRight"
      })
    }
    else {
      this.setState({
        itemClass: "itemLeft",
        itemInfo:"infoLeft",
        itemMedia:"mediaLeft"
      })
    }
  }
  render() {
    return (
      <div className="itemWrapper">
        {this.props.num % 1 === 0 && 
          <div className={this.state.itemClass}>
            <div className={this.state.itemInfo}>
              <div className="itemTitle">{this.props.title}</div>
              <div className="itemDescription">{this.props.description}</div>
              <div id="itemDate">{this.props.date}</div>
            </div>
            <div className={this.state.itemMedia}>
                <video
                  className="vid"
                  alt={this.props.num} 
                  autoPlay="autoplay" 
                  loop="true"
                  playsInline
                  muted>
                  <source src={this.props.video} type="video/mp4"></source>
                </video>
              </div>
          </div>
        }
        {this.props.num % 1 !== 0 && 
          <div className={this.state.itemClass}>
            <div className={this.state.itemMedia}>
                <video
                  className="vid"
                  alt={this.props.num} 
                  autoPlay="autoplay" 
                  loop="true"
                  playsInline
                  muted>
                  <source src={this.props.video} type="video/mp4"></source>
                </video>
              </div>
            <div className={this.state.itemInfo}>
              <div className="itemTitle">{this.props.title}</div>
              <div className="itemDescription">{this.props.description}</div>
              <div className="itemDate">{this.props.date}</div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Item;