import React, { Component } from "react";
// import "./App.css";
import Item from "./item";
import "./main.css"

class App extends Component {
  state = {
    name: "Michael Snower",
    headline: "Software Engineer, AI",
    // each item has a title, descrition, and a video
    items: [
      // {
      //   title:"Brown Visual Computing Lab", 
      //   description:
      //     <div>
      //       Researching applications of Generative Adversarial Networks to image translation and generation. 
      //       Advised by Prof. James Tompkin. We 
      //         <a href="https://arxiv.org/abs/2001.02595" target="blank" style={{"color":"gainsboro"}}>
      //         <span> </span><span style={{"textDecoration":"underline", "color":"gainsboro"}}>recently released</span></a> a publication. 
      //       Its qualitative results are shown.
      //       </div>,
      //   video:require("./media/object_stamps.mp4"),
      //   date:"2019-Present"
      // },
      {
        title:"KeyTrack @ NEC Labs America", 
        description:
                  <div>
                    Qualitative results from <a href="https://arxiv.org/abs/1912.02323" target="blank" style={{"color":"gainsboro"}}>
                    <span style={{"textDecoration":"underline", "color":"gainsboro"}}>"15 Keypoints Is All You Need"</span></a>, the top
                    scoring Human Pose Tracking method on the PoseTrack from Nov. 2019 - March 2020.
                  </div>,
        video:require("./media/keytrack.mp4"),
        date:"2019"
      },
      {
        title:'Mushroom Health Analysis', 
        description:"Collected and analyzed mushroom data with a multispectral camera.",
        video:require("./media/mushrooms.mp4"),
        date:"2018"
      },
      {
        title:"Proximal Policy Optimization", 
        description:<a href="https://github.com/michael-snower/ppo" target="blank"
              style={{"color":"gainsboro"}}>
              Created AI agents to play Atari games
              with <span style={{"textDecoration":"underline", "color":"gainsboro"}}>my implementation of PPO.</span></a>,
        video:require("./media/ppo.mp4"),
        date:"2018"
      },
      {
        title:"FungEYE", 
        description:'Evaluated the likelihood of mold on basil plants with neural network. Finalist in the 2018 RI Business Plan Competition.',
        video:require("./media/fungeye.mp4"),
        date:"2017-2018"
      },
      {
        title:"Bryte Photo", 
        description:"Bryte Photo was a LinkedIn Photo service for university career centers. I grew Bryte from a 'pop-up LinkedIn Photo tent' to serving 1000s of student clients at 9 partner campuses.",
        video:require("./media/brytephoto.mp4"),
        date:"2015-2017"
      },
      {
        title:"Volunteer Yoga Instruction", 
        description:'Taught Yoga Classes at local studios after receiving my RYT Certification.', 
        video:require("./media/yoga.mp4"),
        date:"2015-2016"
      },
    ]
  }
  render() {
    return (
      <div id="appWrapper">
        <div id="app">
          <div id="navLinks">
            <a href="#contact"><span id="contactLink" className="navLink">Contact</span></a>
            <a href="#intro"><span id="topLink" className="navLink">Top</span></a>
          </div>
          <div id="intro">
            <div><img id="headshot" alt="headshot" src={require("./media/headshot.jpg")}></img></div>
            <div id="introText">
              <div id="name">{this.state.name}</div>
              <div id="headline">{this.state.headline}</div>
            </div>
            <div id="topDivider"></div>
          </div>
          <div id="items">
            {this.state.items.map((item, index) => (
              <Item
                key={index}
                num={index}
                title={item.title}
                description={item.description}
                video={item.video}
                date={item.date}
                className="item"
              />
            ))}
          </div>
          <div id="bottomDivider"></div>
          <div id="contact">
            <div id="contactMessage">You may contact me via <span id="contactLinks">
              <a href="mailto:michael_snower@brown.edu" className="contactLink" target="blank">
                <span id="email">email</span>
              </a>, <a href="http://github.com/michael-snower" className="contactLink" target="blank">
                <span id="gitHub">GitHub</span></a>, or <a href="http://linkedin.com/in/michaelsnower" className="contactLink" target="blank">
                <span id="linkedIn">LinkedIn</span>
              </a>.
              Please reach out!</span>
            </div>
          <div id="endSpace"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;

// export default App;
